/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "intro.js/introjs.css";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.introjs-tooltiptext,
.introjs-tooltip-title {
  color: black;
}

@import "theoplayer/ui.css";

.theo-primary-color,
.vjs-selected {
  color: var(--hia-primary-color) !important;
}

.theo-primary-background {
  background-color: var(--hia-primary-color) !important;
}

.theoplayer-chapter-indicator {
  border-left: 3px solid #fff !important;
}

.theoplayer-chapter-indicator-discontinuous {
  border-right: 3px solid #fff !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.hidden {
  display: none !important;
}

.danger {
  color: var(--ion-color-danger) !important;
}

.hia-primary-btn {
  --background: var(--hia-primary-color) !important;
  --color: var(--ion-color-secondary-contrast) !important;
  text-transform: capitalize;
  font-family: var(--hia-font-base);
}

.hia-secondary-btn {
  --background: var(--hia-primary-color) !important;
  --color: var(--ion-color-secondary-contrast) !important;
  --box-shadow: none;
  text-transform: capitalize;
  font-family: "DM Sans";
  font-weight: 600;
  box-shadow: none;
  letter-spacing: -0.01em;
}

.hia-library-btn {
  font-size: 12px;
  font-weight: 700;
  line-height: 15.62px;
  letter-spacing: -0.01em;
  border-radius: 8px;
  text-transform: capitalize;
  font-family: var(--hia-font-base);
  min-height: 32px;
  --background: #22a7d1;
  --color: white;
}

.hia-divider {
  width: 100%;
  height: 2px;
  background-color: #ecf0f1;
}

.hia-label {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.01em;
  margin-bottom: 0.5em;
}

.hia-input-text {
  border: none;
  border: 1px solid #ecf0f1;
  border-radius: 8px;
  padding: 0.7em 1em;
  width: 100%;
  font-weight: 500;
  background-color: white;

  &::placeholder {
    opacity: 0.6;
    color: var(--hia-font-color);
  }
}

.hia-spinner {
  color: var(--hia-primary-color);
  width: 25%;
  height: 25%;
}

.hia-header-search-bar {
  border: 1px solid var(--ion-color-primary);
  border-radius: 0.25rem;
}

.hia-toast {
  --width: fit-content;
  --min-width: 0px;
  --max-width: 700px;
  --background: #0a313d;
  --border-radius: 2rem;
}

.modal-header {
  font-weight: 600;
}

.search-thumbnail {
  border-radius: 4px;
  width: 80px;
  min-width: 80px;
  height: 45px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-color: black;
}

.pointer-cursor {
  cursor: pointer;
}

.disabled-button {
  opacity: 0.4;
  pointer-events: none;
}

.hia-scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}

.k-tabstrip-content,
.k-list-content {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    cursor: pointer;
  }
}

//Animations
/* Define the loading animation */
@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes breathing {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-out-bottom {
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
    display: none;
  }
  100% {
    display: none;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  99% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
    display: none;
  }
  100% {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    opacity: 1;
  }
  100% {
    pointer-events: all;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: all;
  }
  99% {
    opacity: 0;
  }
  100% {
    pointer-events: none;
    display: none;
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    opacity: 1;
  }
  100% {
    pointer-events: all;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: all;
  }
  99% {
    opacity: 0;
  }
  100% {
    pointer-events: none;
    display: none;
    opacity: 0;
  }
}

//Fix Carousel in Safari
.p-carousel-content {
  display: -webkit-flex;
  flex-direction: column;
}

.p-carousel-container {
  display: -webkit-flex !important;
}

.p-carousel-items-container {
  display: flex;
}

.hideNav {
  & .p-carousel-items-container {
    gap: 0.4rem;
  }

  .p-carousel-item {
    flex: 0 !important;
    margin-right: 2rem;
  }
}

.p-carousel-items-content {
  display: block;
  overflow: hidden;
  width: 100%;
}

.p-carousel-indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

//Wrap Spaces in Tooltips
.mdc-tooltip__surface {
  white-space: pre-line;
}

.z-1 {
  z-index: 1;
}

.library-videos-container {
  display: grid;
  justify-content: space-around;
  gap: var(--video-card-library-gap);
  justify-items: start;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  max-width: calc(5 * 15rem + 5rem);

  &.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem;
    justify-items: start;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  @media (min-width: 1387px) and (max-width: 1455px) {
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    justify-items: center;
  }

  @media (min-width: 1455px) and (max-width: 1557px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    justify-items: center;
  }

  @media (min-width: 1557px) and (max-width: 1620px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }

  @media (min-width: 1620px) {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  @media (min-width: 768px) and (max-width: 1387px) {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  }
  @media (max-width: 768px) {
    justify-items: center;
  }
  &-empty {
    margin-top: 4rem;
    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.25px;
      letter-spacing: -0.01em;
      text-align: center;
      margin: 0 auto 0.5rem;
    }
    &-subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.23px;
      text-align: center;
      margin: 0 auto;
      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 18.23px;
        text-align: center;
        color: #22a7d1;
      }
    }
  }
}

.video-qtd {
  flex: 1;
  text-align: end;
  color: rgba(61, 101, 115, 0.8);
  font-family: var(--hia-font-base);
  font-size: 14px;
  font-weight: 600;
  line-height: 16.93px;
}

.color-picker input {
  background: white;
}

.ql-editor {
  p {
    color: white;
  }
}
